import { Maybe } from '@lib/types/Maybe';
import { Link } from '@lib/types/common/Link';
import { ReactNode } from 'react';
import { Link as LinkComponent } from 'ui-components';

type ErrorPageContentProps = {
  title: string;
  description: ReactNode;
  linksHeading?: Maybe<string>;
  links?: Link[];
};

export const ErrorPageContent = ({
  title,
  description,
  linksHeading,
  links,
}: ErrorPageContentProps) => {
  return (
    <div className="container mb-32 mt-8">
      <h1 data-test="error-404-not-found" className="text-2xl font-medium">
        {title}
      </h1>
      <p className="text-2xl text-grey-500">{description}</p>
      {linksHeading && (
        <h2 className="mt-4 text-sm text-grey-500">{linksHeading}</h2>
      )}
      {links && links.length > 0 && (
        <ul className="mt-4">
          {links.map((link) => (
            <li key={link.href}>
              <LinkComponent href={link.href} className="text-sm text-blue">
                {link.value}
              </LinkComponent>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
